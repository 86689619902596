<template>
  <div>
    <div class="pad5" :style=" message.from_person_id === 'Pascal' ? 'display: flex; justify-content: end' : 'display: flex; justify-content: start' " >
      <div :style=" message.from_person_id === 'Pascal' ? 'text-align: right' : 'text-align: left' ">
        <span style="font-weight: bold">{{ message.from_person_id }}</span>
      </div>
    </div>
    <div class="pad5" :style=" message.from_person_id === 'Julia' ? 'text-align: left':'text-align: right' ">
      <div>
        <div v-if="message.mssg === 'Sprachnachricht'">
          <div v-show="!message.showControl">
            <button style="padding: 10px" @click="showControl(message)">Sprachnachricht abspielen</button>
          </div>
          <div v-if="message.showControl">
            <play-mp3 :message="message"/>
          </div>
        </div>
        <div v-else v-html="message.mssg" class="text">
        </div>
      </div>
      <div class="pad10" style="font-size: 0.8em; color: gray">
        {{ formattedDate }}
      </div>
    </div>
  </div>
</template>

<style>
.text {
  font-size: 1.2em;
}
.pad10 {
  padding-top: 10px;
}
.pad5 {
  padding-top: 5px;
}
</style>
<script>
import dateFmtOptions from "@/components/dateFmtOptions";
import PlayMp3 from "@/components/play-mp3.vue";

export default {
  name: 'MessageComponent',
  components: {PlayMp3},
  computed: {
    formattedDate(){
      return new Date(this.message.date).toLocaleString('de-DE', dateFmtOptions)
    }
  },
  methods: {
    showControl(message) {
      message.showControl = !message.showControl;
    }
  },
  props: {
    message: {}
  }
}
</script>

<style>

</style>
