export default async function  importPrivateKey (base64Key) {
    const privKeyData = new Uint8Array(atob(base64Key).split('').map(c => c.charCodeAt(0)));
    return window.crypto.subtle.importKey(
        "pkcs8",
        privKeyData,
        {
            name: "RSA-OAEP",
            hash: {name: "SHA-256"},
        },
        false,
        ["decrypt"]
    );
}
