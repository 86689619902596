<template>
  <div style="display: flex; justify-content: center; word-break: break-word">
    <div v-show="!showText">
      Gib hier deinen Key ein
      <div>
        <textarea id="enteredKey" cols="40" rows="30" v-bind:value="enteredPrivateKey">
        </textarea>
      </div>
      <button @click="decrypt()" style="font-size: 1.1em; padding: 1em">Anmelden</button>
    </div>
    <div v-show="showText">
      <div v-show="!restoredMessages">
        Entschlüssele unseren Chatverlauf ...
      </div>
      <div v-show="restoredMessages">
        <ul>
          <li v-for="message in restoredMessages" :key="message" class="messageEntry">
            <Message :message="message"></Message>
          </li>
        </ul>
      </div>
    </div>

  </div>
</template>

<script>

import Message from './Message.vue'
import state from "@/components/state";
import getEncryptedMessages from "@/components/get-encrypted-messages";
import importPrivateKey from "@/components/import-private-key";
import decryptMessage from "@/components/decrypt-message";
import sha256 from "@/components/sha256";

export default {

  name: 'DecryptComponent',
  components: {
    Message
  },
  methods: {
    async decrypt() {

      let enteredKey = document.getElementById('enteredKey').value;
      const fingerprint = await sha256(enteredKey);
      sessionStorage.setItem("fp", fingerprint);

      this.encryptedMessages = await getEncryptedMessages();
      console.log('encrypted messages: ', this.encryptedMessages.length)
      state.privateKey = await importPrivateKey(enteredKey);

      this.encryptedMessages.forEach((message) => {
        decryptMessage(message, state.privateKey).then(decrypted => {
          this.decryptedMessages.push(decrypted);
          if (this.decryptedMessages.length === this.encryptedMessages.length) {
            console.log('finished decryption, decryptedMessages:', this.decryptedMessages.length);
            this.restoredMessages = JSON.parse(this.decryptedMessages.join('')).messages
            this.restoredMessages.forEach(m => {
              let date = new Date(0);
              date.setUTCSeconds(m.date_modified);
              m.date = date;
            });
            this.showText = true;
          }
        });
      });
    }
  },
  data() {
    return {
      showText: false,
      encryptedMessages: [],
      decryptedMessages: [],
      restoredMessages: null,
      enteredPrivateKey: ''
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.messageEntry {
  width: 90%;
  padding-bottom: 1em;
}
</style>
