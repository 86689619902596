<template>
  <div :id="divId" ref="audioContainer" style="padding: 10px;">
    <div v-if="loading">Sprachnachricht wird entschlüsselt: {{percent}}%</div>
  </div>
</template>
<script>
import decryptMessage from "@/components/decrypt-message";
import state from "@/components/state";

export default {
  name: 'play-mp3',
  props: {
    message: {},
  },
  data() {
    return {
      mp3: 'data:audio/mp4;base64,',
      divId: '',
      audioElement: null,
      loading: false,
      processed: 0,
      numberOfLines: 0
    }
  },
  computed: {
    percent() {
      return Math.round(this.processed / this.numberOfLines * 100);
    }
  },
  mounted() {
    this.divId = 'mp3-' + this.message.uid;

    this.getMp3(this.message.multimedia.audio.url).then(mp3 => {
      this.mp3 += mp3;
      this.createAudioSource(this.mp3);
    });
  },
  methods: {
    createAudioSource(src) {
      // Remove any existing audio element
      if (this.audioElement) {
        this.$refs.audioContainer.removeChild(this.audioElement);
      }

      // Create a new audio element
      const audio = document.createElement('audio');
      audio.src = src;

      // Add controls if desired
      audio.controls = true;
      audio.autoplay = true;

      // Add event listeners if needed
      audio.addEventListener('ended', () => {
        console.log('Audio playback ended');
      });

      // Append the audio element to the DOM
      this.$refs.audioContainer.appendChild(audio);

      // Update the component's data
      this.audioElement = audio;

      return audio;
    },
    async getMp3(url) {
      this.loading = true;
      let apiUrl = '/audio?file=' + url;

      const data = await fetch(apiUrl, {
        headers: {
          'Authorization': sessionStorage.getItem("fp")
        }
      });
      const json = await data.json();
      const encryptedLines = json.content;
      this.numberOfLines = encryptedLines.length;
      const promises = [];

      for (let i = 0; i < encryptedLines.length; i++) {
        let promise = new Promise((resolve, reject) => {
          decryptMessage(encryptedLines[i], state.privateKey).then(line => {
            this.processed++;
            resolve({line: line, index: i, decryptedLine: line});
          }).catch(error => {
            reject(error);
          });
        });
        promises.push(promise);
      }

      const r = await Promise.all(promises);
      r.sort((a, b) => a.index - b.index);
      const decryptedLines = r.map(obj => obj.decryptedLine);
      this.loading = false;
      return btoa(decryptedLines.join(''));
    }
  }
}
</script>
