export default async function decryptMessage(message, key) {
    let decrypted = await window.crypto.subtle.decrypt(
        {
            name: "RSA-OAEP"
        },
        key,
        new Uint8Array(atob(message).split('').map(c => c.charCodeAt(0)))
    );
    let dec = new TextDecoder();
    return dec.decode(decrypted);
}
