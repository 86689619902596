export default async function getEncryptedMessages() {
    const fingerprint = sessionStorage.getItem("fp");
    const response = await fetch('/data',{
        headers: {
            'Authorization': fingerprint
        }
    });
    const json = await response.json();
    return json.content;
}
